var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-container"},[_vm._m(0),_c('div',{staticClass:"con"},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"订单名称:","prop":"name"}},[_c('el-input',{staticClass:"add-ipt",attrs:{"clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"运营指派:","prop":"name"}},[_c('el-select',{staticClass:"add-ipt",attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},[_c('el-option',{attrs:{"label":"区域一","value":"shanghai"}})],1)],1),_c('el-form-item',{attrs:{"label":"通知人:","prop":"name"}},[_c('el-select',{staticClass:"add-ipt",attrs:{"placeholder":"请选择","clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},[_c('el-option',{attrs:{"label":"区域一","value":"shanghai"}})],1)],1),_c('el-form-item',{attrs:{"label":"备注:","prop":"name"}},[_c('el-input',{staticClass:"add-ipt",attrs:{"type":"textarea","rows":2,"placeholder":"请输入内容","clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{staticClass:"upload-file",attrs:{"label":"附件:","prop":"name"}},[_c('div',{staticClass:"orderFile-container"},[_c('div',{staticClass:"orderFileBtn"},[_vm._v(" 上传附件 "),_c('input',{staticClass:"orderFile-input",attrs:{"type":"file"},on:{"input":function (e) {
                  _vm.orderFile(e);
                }}})]),_vm._v(" 上传文件及图片，大小不超过50MB ")]),_c('div',{staticClass:"fileList-container"},_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"fileList-item"},[_c('div',{staticClass:"item-name"},[_c('common-picture',{attrs:{"fileUrl":item.fileUrl,"fileType":_vm.Config.fileType.imgType.includes(
                    item.fileUrl.split('.')[
                      item.fileUrl.split('.').length - 1
                    ]
                  )
                    ? 'imgText'
                    : 'file',"fileName":item.fileName}})],1),_c('div',{staticClass:"deleteBtn",on:{"click":function () {
                  _vm.deleteFlie(index);
                }}},[_vm._v(" 删除 ")])])}),0)]),_c('div',{staticClass:"gap2"}),_c('el-form-item',{staticClass:"introducer",attrs:{"label":"介绍人信息:","prop":"name"}},[_c('el-checkbox',{model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("是否有介绍人")])],1),_c('el-form-item',{attrs:{"label":"介绍人名称:","prop":"name"}},[_c('el-input',{staticClass:"add-ipt",attrs:{"clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"介绍人联系方式:","prop":"address1"}},[_c('el-input',{staticClass:"add-ipt",attrs:{"clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"介绍费:","prop":"address2"}},[_c('el-input',{staticClass:"add-ipt lastIpt",attrs:{"clearable":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_vm._v("元 ")],1)],1)],1),_c('div',{staticClass:"gap3"}),_c('div',{staticClass:"sixth"},[_c('el-button',{staticClass:"sett-return btnwid",attrs:{"plain":""}},[_vm._v("取消")]),_c('el-button',{staticClass:"btnwid",attrs:{"type":"primary"}},[_vm._v("确定")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('span',[_vm._v("客户报价")])])}]

export { render, staticRenderFns }