<!-- 生成订单 -->
<template>
  <div class="create-container">
    <div class="header"><span>客户报价</span></div>
    <div class="con">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="订单名称:" prop="name">
          <el-input v-model="form.name" class="add-ipt" clearable></el-input>
        </el-form-item>
        <el-form-item label="运营指派:" prop="name">
          <el-select
            class="add-ipt"
            v-model="form.name"
            placeholder="请选择"
            clearable
          >
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通知人:" prop="name">
          <el-select
            v-model="form.name"
            placeholder="请选择"
            class="add-ipt"
            clearable
          >
            <el-option label="区域一" value="shanghai"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:" prop="name">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="form.name"
            clearable
            class="add-ipt"
          ></el-input>
        </el-form-item>
        <el-form-item label="附件:" prop="name" class="upload-file">
          <div class="orderFile-container">
            <div class="orderFileBtn">
              上传附件
              <input
                class="orderFile-input"
                type="file"
                @input="
                  (e) => {
                    orderFile(e);
                  }
                "
              />
            </div>
            上传文件及图片，大小不超过50MB
          </div>
          <div class="fileList-container">
            <div
              v-for="(item, index) in fileList"
              :key="index"
              class="fileList-item"
            >
              <div class="item-name">
                <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="
                    Config.fileType.imgType.includes(
                      item.fileUrl.split('.')[
                        item.fileUrl.split('.').length - 1
                      ]
                    )
                      ? 'imgText'
                      : 'file'
                  "
                  :fileName="item.fileName"
                ></common-picture>
              </div>
              <div
                @click="
                  () => {
                    deleteFlie(index);
                  }
                "
                class="deleteBtn"
              >
                删除
              </div>
            </div>
          </div>
        </el-form-item>
        <div class="gap2"></div>
        <el-form-item label="介绍人信息:" prop="name" class="introducer">
          <el-checkbox v-model="checked">是否有介绍人</el-checkbox>
        </el-form-item>
        <el-form-item label="介绍人名称:" prop="name">
          <el-input v-model="form.name" class="add-ipt" clearable></el-input>
        </el-form-item>
        <el-form-item label="介绍人联系方式:" prop="address1">
          <el-input v-model="form.name" class="add-ipt" clearable></el-input>
        </el-form-item>
        <el-form-item label="介绍费:" prop="address2">
          <el-input
            v-model="form.name"
            class="add-ipt lastIpt"
            clearable
          ></el-input
          >元
        </el-form-item>
      </el-form>
    </div>
    <div class="gap3"></div>
    <div class="sixth">
       <el-button plain class="sett-return btnwid">取消</el-button>
       <el-button type="primary" class="btnwid">确定</el-button>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../utils/index.js";
import { upload } from "../../service/upload.js";
import CommonPicture from "../../components/common/commonPicture.vue";

export default {
  name: "createOrder",
  components: {
    CommonPicture
  },
  props: {},
  data() {
    return {
      form: {
        name: "",
        address1: [],
        address2: ""
      },
      rules: {
        name: [{ required: true, message: "请输入内容", trigger: "blur" }]
      },
      fileList: [],
      checked: true
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 上传文件
    async orderFile(e) {
      this.loading = true;
      let fileList = [...this.fileList];
      let file = e.target.files[0];
      console.log(file, "?????????");
      let fileName = file.name;
      if (file.size / 1024 > 1024 * 50) {
        this.loading = false;
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        this.loading = false;
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      let resData = (await upload({ file: file })).data;
      // 上传接口
      // await payoutUpload({id: this.tableRow.id, filedId: resData.id, fileName: fileName, filedUrl: resData.filename})
      fileList.push({ fileName: fileName, fileUrl: resData.filename });
      this.fileList = fileList;
      e.target.value = "";
      this.loading = false;
    },
    deleteFlie(i) {
      let fileList = [...this.fileList];
      fileList = fileList.filter((item, index) => {
        return i != index;
      });
      this.fileList = fileList;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.create-container {
  .header {
    height: 75px;
    background-color: #f6f9ff;
    text-align: left;
    border-bottom: 1px solid #b5d1ff;
    span {
      display: inline-block;
      font-size: 18px;
      margin-top: 28px;
      margin-left: 130px;
    }
  }
  .el-form {
    // position: relative;
    // width: 570px;
    margin-top: 32px;
    margin-left: 130px;
    .add-ipt {
      width: 434px;
    }
    .delIcon {
      position: relative;
    }
    .plus {
      position: relative;
    }
  }
  /deep/.el-form-item__content{
      text-align: left;
  }
  .orderFile-container {
    padding-left: 7px;
    text-align: left;
    .orderFileBtn {
      width: 100px;
      height: 32px;
      line-height: 32px;
      border: 1px solid rgba(220, 223, 230, 100);
      color: #666;
      background: #fff;
      text-align: center;
      font-size: 14px;
      position: relative;
      border-radius: 4px;
      display: inline-block;
      .orderFile-input {
        cursor: pointer;
        font-size: 0;
        width: 100px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }
    }
  }
  .fileList-container {
    .fileList-item {
      display: flex;
      justify-content: space-between;
      width: 320px;
      .item-name {
        font-size: 12px;
        color: #333;
      }
      .deleteBtn {
        color: #1890ff;
        cursor: pointer;
      }
    }
  }
  .lastIpt{
      width: 25% !important;
      margin: 0 16px 0 0px;
    //   padding: 0 7px;
  }
  .con{
      position: relative;
  }
  .gap {
    height: 16px;
    background: #eee;
    margin: 32px 0;
  }
  .gap2 {
    position: absolute;
    top: 406px;
    left: 0;
    right: 0;
    height: 16px;
    background: #eee;
  }
  .gap3 {
    height: 32px;
    background: #eee;
    margin: 32px 0;
  }
  .upload-file{
      margin-bottom: 144px;
  }
  .introducer{
      margin-top: 32px;
  }
  .sixth{
    // display: flex;
    // align-items: center;
    text-align: right;
    margin-right: 101px;
  }
  .sett-return{
    border-color: #1890ff;
    color: #1890ff;
  }
  .btnwid{
      width: 97px;
      height: 40px;
  }
}
</style>
